<template>
  <div class="searchBox">
    <p class="searchLabel" v-if="searchLabel !== ''">{{ searchLabel }}</p>
    <el-input v-model="baseData.searchVal" :placeholder="placeholder" @keyup.enter="searchHandle(true)"/>
    <el-button type="primary" :loading="baseData.limitSwitch" @click="searchHandle(true)">搜索</el-button>
    <el-button type="primary" plain :loading="baseData.limitSwitch" @click="searchHandle(false)">清空</el-button>
  </div>
</template>

<script setup>
// eslint-disable-next-line
import { reactive, defineEmits, defineProps, onMounted, watch } from 'vue'

const baseData = reactive({
  limitSwitch: false,
  timeZone: '',
  searchVal: ''
})
const props = defineProps({
  searchLabel: {
    type: String,
    default: '搜索：'
  },
  searchVal: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: '请输入用户组名称'
  }
})

const emit = defineEmits([])

const searchHandle = (val) => {
  if (baseData.limitSwitch) return
  baseData.limitSwitch = true
  baseData.timeZone = setTimeout(() => {
    baseData.limitSwitch = false
  }, 1000)
  !val && (baseData.searchVal = '')
  emit('searchCall', val, baseData)
}

onMounted(() => {
  baseData.searchVal = props.searchVal
})
watch(() => props.searchVal, (newValue) => {
  baseData.searchVal = newValue
})
</script>

<style lang="scss" scoped>
.searchBox {
  display: flex;
  align-items: center;
  margin-right: 24px;
  .el-input {
    margin-right: 6px;
  }
  .el-button + .el-button {
    margin-left: 6px;
  }
  .searchLabel {
    color: #606266;
    margin: 0 6px 0 12px;
    font-size: 14px;
    min-width: 46px;
    text-align: left;
  }
}
</style>
